<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Application" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <DetailViewBoxComponent title="Détail d'une application" id="detailid">
          <template v-slot:items>
            <InputFieldComponent :cols="cols" label="label" :value="label" />

            <InputFieldComponent
              :cols="cols"
              label="nom digital"
              :value="digitalName"
            />

            <InputFieldComponent
              :cols="cols"
              label="mots clés"
              :value="keywords"
            />

            <InputFieldComponent label="app collaborateur" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  :value="isgroup"
                  readonly
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent
              :cols="cols"
              label="service"
              :value="serviceName"
            />
            
            <InputFieldComponent
              :cols="cols"
              label="catégorie"
              :value="serviceCategory"
            />

            <InputFieldComponent :cols="cols" label="ordre dans la catégorie" :value="ranking" />

            <InputFieldComponent label="nouveauté" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :value="isnew"
                  readonly
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent :cols="cols" label="url" :value="url" />

            <InputFieldComponent label="actif" :cols="cols" :divider="true">
              <template v-slot:value>
                <v-checkbox
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :value="isactive"
                  readonly
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="ouvrir dans un nouvel onglet" :cols="cols" >
              <template v-slot:value>
                <v-checkbox
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :value="openinnewtab"
                  readonly
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent
              :cols="cols"
              label="nom de l'icône"
              :value="iconName"
            />

            <InputFieldComponent
              :cols="cols"
              label="ordre dans le menu"
              :value="menuRanking"
              :divider="false"
            />
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

// import { addFieldWithOneValue } from "@/components/ui/detail_view_component_tool.js";

export default {
  name: "DetailsApplication",
  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement du service. */
      service: null,

      /**le service d'accès aux services */
      serviceServices: null,

      /**l'identifiant de l'entité à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**le libellé de l'application */
      label: null,

      /**le nom digital de l'application */
      digitalName: null,

      /**les mots clés */
      keywords: null,

      /**est-ce une application collaborateur? */
      isgroup: false,

      /**le nom du service */
      serviceName: null,

      /**Ordre dans la catégorie */
      ranking: 0,

      /**est-ce une nouveauté? */
      isnew: false,

      /**url de l'application */
      url: null,

      /**est-ce que l'applcation est active? */
      isactive: false,

      /** Appllication doit être ouverte dans un nouvel onglet */
      openinnewtab: null,

      /** La catégorie de l'application */
      serviceCategory: null,

      /** Nom de l'icone */
      iconName: null,

      /** Position dans le menu */
      menuRanking: null,

      cols: [5, 7],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        //Récupération de l'item par son id
        let entity = await this.service.getById(this.entityId);

        let serviceName = "-";

        if (entity.serviceId) {
          let srv = await this.serviceServices.getServiceById(entity.serviceId);
          serviceName = srv.name;
        }

        entity.serviceName = serviceName;

        this.init(entity);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init(entity) {
      this.label = entity.label || "-";
      this.digitalName = entity.digitalName || "-";
      this.keywords = entity.keywords || "-";
      this.isgroup = entity.isgroup;
      this.serviceName = entity.serviceName || "-";
      this.ranking = entity.ranking || "-";
      this.isnew = entity.isnew;
      this.url = entity.url || "-";
      this.isactive = entity.isactive;
      this.openinnewtab = entity.openinnewtab;
      this.serviceCategory = entity.serviceCategory || "-";
      this.iconName = entity.iconName || "-";
      this.menuRanking = entity.menuRanking || "-";
    },
    getClassForCheckbox(value) {
      return value ? "success" : null;
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.service = new ApplicationService(this.$api.getApplicationApi());

    this.serviceServices = new ServicesService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>